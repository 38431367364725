import "ds-ui/generated/styles.css";
import "assets/styles/index.css";
import React from "react";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme, setupCssVars } from "ui";

export function StylesWrapper({ children, skipCssVars, injectTheme }: any) {
  if (!skipCssVars) {
    setupCssVars();
  }
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={injectTheme ?? theme}>
        <React.Fragment>
          <CssBaseline />
          {children}
        </React.Fragment>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
