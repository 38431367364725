import { lazy } from "react";
import { ENV } from "runenv";
import { Feature } from "shared/features";
import { Permission } from "api/types";
import { NotFound } from "modules/router/pages";
import { lazyImport, redirectIfAuthenticated, namedLazy } from "../modules/router";
import { ROUTES_PATHS } from "./routesPaths";

const LayoutClassic = namedLazy(
  () => import("modules/layout/organisms/LayoutClassic"),
  "LayoutClassic"
);

export type Route = {
  path: string;
  component: React.ElementType;
  layout?: any;
  disabled?: boolean;
  requiredPermissions?: Permission[];
  requiredPermissionsAnyOf?: Permission[];
  children?: Route[];
  index?: boolean;
};

export const routes: Record<string, Route> = {
  jwtAuth: {
    path: ROUTES_PATHS.JWT_AUTH,
    component: lazy<any>(() => import("modules/auth/pages/AuthVerifyLoggedUser")),
  },
  login: {
    path: ROUTES_PATHS.LOGIN,
    component: redirectIfAuthenticated(lazy<any>(() => import("modules/auth"))),
  },
  cyberhavenLogin: {
    path: ROUTES_PATHS.CYBERHAVEN_LOGIN,
    component: redirectIfAuthenticated(
      lazy<any>(() => import("modules/auth/pages/CyberhavenLoginPage"))
    ),
  },
  registration: {
    path: ROUTES_PATHS.REGISTRATION,
    component: lazy<any>(() => import("modules/auth/pages/RegistrationPage")),
  },
  monitoring: {
    path: ROUTES_PATHS.MONITORING,
    layout: LayoutClassic,
    component: lazy(() => import("modules/monitoring")),
  },
  alerts: {
    path: ROUTES_PATHS.ALERTS,
    layout: LayoutClassic,
    component: namedLazy(() => import("modules/risks/alerts-table"), "AlertsPage"),
    requiredPermissionsAnyOf: ["assigned_incident__read", "incident__read"],
  },
  listsMatching: {
    path: ROUTES_PATHS.LISTS_MATCHING,
    layout: LayoutClassic,
    component: lazyImport(() =>
      import("modules/matching-lists/ListsMatchingPage").then((el) => el.ListsMatchingPage)
    ),
    requiredPermissions: [
      "list__read",
      ...((ENV.FEATURES.FE_DATA_MASKING_ENABLED ? ["pii__read"] : []) as Permission[]),
    ],
    disabled: !ENV.FEATURES.MATCHING_LISTS_ENABLED,
  },
  sensors: {
    path: ROUTES_PATHS.SENSORS,
    layout: LayoutClassic,
    component: lazyImport(() => import("modules/sensors").then((el) => el.SensorsPage)),
    requiredPermissions: ["cloud_sensor__read"],
    children: [
      {
        index: true,
        path: `${ROUTES_PATHS.SENSORS}/`,
        component: lazyImport(() => import("modules/sensors").then((el) => el.IndexPage)),
        requiredPermissions: ["cloud_sensor__read"],
      },
      {
        path: `${ROUTES_PATHS.SENSORS}/gsuite/:uuid`,
        component: lazyImport(() => import("modules/sensors").then((el) => el.GSuitePage)),
        requiredPermissions: ["cloud_sensor__read"],
      },
      {
        path: `${ROUTES_PATHS.SENSORS}/salesforce/*`,
        component: lazyImport(() => import("modules/sensors").then((el) => el.SalesforcePage)),
        requiredPermissions: ["cloud_sensor__read"],
      },
      {
        path: `${ROUTES_PATHS.SENSORS}/o365/:uuid`,
        component: lazyImport(() => import("modules/sensors").then((el) => el.O365Page)),
        requiredPermissions: ["cloud_sensor__read"],
      },
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
  administration: {
    path: ROUTES_PATHS.ADMINISTRATION,
    layout: LayoutClassic,
    component: lazyImport(() =>
      import("modules/administration").then((el) => el.AdministrationPage)
    ),
    requiredPermissions: [],
  },
  preferences: {
    path: ROUTES_PATHS.PREFERENCES,
    layout: LayoutClassic,
    component: lazyImport(() => import("modules/preferences").then((el) => el.PreferencesPage)),
    requiredPermissionsAnyOf: [
      "user__read",
      "api_keys__read",
      "role__read",
      "scope__read",
      "global_settings__read",
      "global_auth_settings__read",
      "dlp_rules__read",
      "file_tags__read",
      "admin_global_settings__read",
      "admin_saml__read",
      "api_token__read",
      "directory_sync__read",
    ],
  },
  endpoint: {
    path: ROUTES_PATHS.ENDPOINT,
    layout: LayoutClassic,
    component: lazy(() => import("modules/endpoint-sensors")),
    requiredPermissions: ["endpoint__read"],
  },
  risksDashboard: {
    path: ROUTES_PATHS.RISKS_DASHBOARD,
    layout: LayoutClassic,
    component: namedLazy(() => import("modules/risks/dashboard/RisksDashboard"), "RiskDashboard"),
    requiredPermissions: ["dataset__read", "policy__read", "flow_stats__read"],
  },
  sharableListView: {
    path: ROUTES_PATHS.SHARABLE_LISTVIEW,
    component: lazyImport(() =>
      import("modules/sharable-list-view").then((el) => el.SharableListView)
    ),
    requiredPermissions: ["dataset__read", "policy__read", "audit__read", "flow_stats__read"],
  },
  profile: {
    path: ROUTES_PATHS.PROFILE,
    layout: LayoutClassic,
    component: namedLazy(() => import("modules/users/pages/MyProfilePage"), "MyProfilePage"),
    requiredPermissions: ["console__read"],
  },
  portal: {
    path: ROUTES_PATHS.PORTAL,
    layout: LayoutClassic,
    component: lazy(() => import("modules/portal")),
    requiredPermissions: ["console__read"],
  },
  apiTokens: {
    path: ROUTES_PATHS.API_TOKENS,
    layout: LayoutClassic,
    component: namedLazy(() => import("modules/api-tokens"), "TokensPage"),
    requiredPermissions: ["api_token__read", "api_token__modify"],
  },
  forgotPassword: {
    path: ROUTES_PATHS.FORGOT_PASSWORD,
    component: namedLazy(
      () => import("modules/auth/pages/ForgotPasswordPage"),
      "ForgotPasswordPage"
    ) as any,
  },
  reset: {
    path: ROUTES_PATHS.RESET,
    component: lazy<any>(() => import("modules/auth/pages/RegistrationPage")),
  },
  apittokens2: {
    path: ROUTES_PATHS.APITTOKENS2,
    component: lazyImport<any>(() => import("modules/api-tokens-v2").then((el) => el.TokensPage)),
  },
  diagnostics: {
    path: ROUTES_PATHS.ENDPOINT_DIAGNOSTICS,
    component: lazyImport<any>(() => import("modules/endpoint-diagnostics").then((el) => el.Page)),
    requiredPermissions: ["console__read"],
  },
  analyticsDashboard: {
    path: ROUTES_PATHS.ANALYTICS_DASHBOARD("/*"),
    layout: LayoutClassic,
    component: lazyImport(() =>
      import("modules/visual-analytics/analytics-dashboard/AnalyticsDashboard").then(
        (el) => el.AnalyticsDashboard
      )
    ),
    disabled: !ENV.SUPERSET_URL,
    requiredPermissions: ["visual_analytics__read"],
  },
  insiderRisks: {
    path: ROUTES_PATHS.INSIDER_RISKS,
    layout: LayoutClassic,
    component: lazyImport(() =>
      import("modules/risks/users-table/RiskyUsersPage").then((el) => el.RiskyUsersPage)
    ),
    disabled: !ENV.FEATURES[Feature.INSIDER_RISK_MANAGEMENT_TABLE],
    requiredPermissions: [
      "irm__read",
      "incident__read",
      "dataset__read",
      "policy__read",
      "flow_stats__read",
    ],
  },
  landing: {
    path: ROUTES_PATHS.LANDING,
    layout: LayoutClassic,
    component: lazyImport(() =>
      import("modules/visual-analytics/landing/LandingPage").then((el) => el.LandingPage)
    ),
    disabled: !ENV.FEATURES.LANDING_PAGE || !ENV.SUPERSET_URL,
    requiredPermissions: ["console__read"],
  },
};

export const routesArray = (
  [
    "administration",
    "alerts",
    "sensors",
    "preferences",
    "endpoint",
    "profile",
    "monitoring",
    "apiTokens",
    "risksDashboard",
    "portal",
    "registration",
    "login",
    "cyberhavenLogin",
    "forgotPassword",
    "reset",
    "jwtAuth",
    "sharableListView",
    "apittokens2",
    "analyticsDashboard",
    "insiderRisks",
    "listsMatching",
    "landing",
    "diagnostics",
  ] as Array<keyof typeof routes>
)
  .map((el) => routes[el] as Route)
  .filter((route) => !route.disabled);
