import axios from "axios";
import memoizeOne from "memoize-one";
import { suggestapi, suggestapi2, api as coreApi } from "api";
import {model} from "../gen";

export function suggestWord() {
  // Removed empty values because of recent refactoring to big query. https://github.com/CyberhavenInc/dataflow/pull/10233
  // This api was producing too many queries to backend that can't be cached.
  return Promise.resolve({
    records: [],
  });
}

export function suggestFieldValues(searchRequest: any) {
  return coreApi.suggestapi2.Service.MultiTyped(searchRequest as suggestapi2.MultiTypedRequest);
}
class SuggestApi {
  url = "/v1/suggest";

  suggestWords = (): Promise<{ records: any[] }> => {
    // Removed empty values because of recent refactoring to big query. https://github.com/CyberhavenInc/dataflow/pull/10233
    // This api was producing too many queries to backend that can't be cached.
    return Promise.resolve({
      records: [],
    });
  };

  fetchFileExtensions = memoizeOne(() => {
    return axios.get<suggestapi.FileTypesResponse>("/v1/suggest/file-types").then((el) => {
      return {
        file_types: el.data.file_types,
        custom_types: { Custom: el.data.custom_types || [] },
      };
    });
  });

  addCustomExtension = (extension: string) => {
    return axios
      .put<model.CustomFileType>("/v1/suggest/file-types", { Extension: extension })
      .then((el) => {
        return el.data.extension;
      });
  };

  deleteCustomExtension = (extension: string) => {
    return axios.delete("/v1/suggest/file-types", { data: { Extension: extension } }).then(() => {
      return extension;
    });
  };
}

export const api = new SuggestApi();
export { SuggestApi };
